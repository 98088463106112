import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { RootState } from "../../../store";
import collectExpiredOffer from "../redux/collectExpiredOffer";
import { BigNumber } from "ethers";
import acceptOffer from "../redux/acceptOffer";

export type UseAcceptOfferOutput = {
  acceptOffer: (offerId: string, wftmAmount: BigNumber) => Promise<void>;
  isAcceptingOffers: string[];
}

export default function useAcceptOffer(): UseAcceptOfferOutput {
  const dispatch = useAppDispatch()
  const { acceptingOffers } = useSelector((state: RootState) => state.offers);
  const boundAcceptOffer = useCallback(async (offerId: string, wftmAmount: BigNumber) => {
    dispatch(acceptOffer(offerId, wftmAmount))
  }, [dispatch]);

  return useMemo(() => ({
    acceptOffer: boundAcceptOffer,
    isAcceptingOffers: acceptingOffers
  }), [boundAcceptOffer, collectExpiredOffer]);
}