export const isNumeric = (x: unknown): x is number => typeof x === 'number' && !isNaN(x);

export const isFiniteNumber = (x: unknown): boolean => isNumeric(x) && isFinite(x);

const normalizeNumber = (numeric: number | string) => {
  const radix = typeof numeric === 'number' ? 10 : 16;
  return parseInt(numeric.toString(), radix);
}

export const isMatch = (a: number | string, b: number | string) => {
  return normalizeNumber(a) === normalizeNumber(b);
}