import styled from "styled-components";

export type InputProps = {
  id?: string;
  className?: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const Container = styled.div`
    position: relative;
`;

const StyledInput = styled.input`
    border-radius: .25rem;
    width: 100%;
    background-color: #b9e3f5;
    padding: .5rem 3rem;

    &:disabled {
      opacity: .5;
    }
`;

const Input: React.FC<InputProps> = ({
  id,
  className,
  disabled,
  value,
  onChange
}) => {
  return (
    <Container className={className}>
      <StyledInput disabled={disabled} id={id} value={value} onChange={(event) => onChange(event.target.value)} />
    </Container>
  );
}

export default Input;