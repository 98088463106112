import styled from "styled-components";
import ConnectWalletButton from "../../components/ConnectWalletButton";
import Button from "../../components/Button";
import { useState } from "react";
import Modal from "../../components/Modal";
import { utils } from "ethers";
import Input from "../../components/Input";
import useCreateOffer from "../../features/offers/hooks/useCreateOffer";
import ActiveOffersTable from "./components/ActiveOffersTable";
import ExpiredOffersTable from "./components/ExpiredOffersTable";
import useAppSelector from "../../hooks/useAppSelector";
import { FDOG_DECIMALS, WFTM_DECIMALS } from "../../constants";
import { fantom, FANTOM_NETWORK_ID } from "../../networks/configurations/fantom";
import { isMatch } from "../../utils/numbers";

const FDogImage = styled.img.attrs({ src: '/images/fdog.png' })`
  border-radius: .25rem;
`;

const FullWidthButton = styled(Button)`
  width: 100%;
`;

const TabButton = styled(FullWidthButton)`
  cursor: ${props => props.variant === 'contained' ? 'not-allowed' : 'pointer'};
  pointer-events: ${props => props.variant === 'contained' ? 'none' : 'initial'};
`;

const TableContainer = styled.div`
  background-color: #eff7fb;
  min-height: 300px;
`;

const HeaderImage = styled.img.attrs({ src: '/images/logo.jpg', className: 'mx-auto' })``;

export default function MainView() {
  const [isCreateOfferModalOpen, setIsCreateOfferModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<'active' | 'expired'>('active');
  const [fdogAmount, setFDogAmount] = useState<string>('0')
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [price, setPrice] = useState<string>('0')
  const [minutes, setMinutes] = useState<string>('0');
  const { connected: isConnected, networkId } = useAppSelector(state => state.wallet);
  const isConnectedToCorrectNetwork = isConnected && isMatch(networkId, FANTOM_NETWORK_ID);
  const { createOffer, isCreatingOffer } = useCreateOffer();

  const handleCreateOfferClick = () => {
    setIsCreateOfferModalOpen(true);
  }

  const handleCreateOfferModalClose = () => {
    setIsCreateOfferModalOpen(false);
  }

  const handleCreateOrder = async () => {
    setInputsDisabled(true);
    try {
      await createOffer(
        utils.parseUnits(fdogAmount, FDOG_DECIMALS),
        utils.parseUnits(price, WFTM_DECIMALS),
        Number(minutes)
      );
    } finally {
      setInputsDisabled(false);
    }

    setIsCreateOfferModalOpen(false);
  }

  const renderWalletConnectedInfo = () => {
    if (isConnected) {
      if (!isConnectedToCorrectNetwork) {
        return <><b>Wrong chain connected,</b> {`change to ${fantom.name}`}</>;
      }
      return 'Wallet connected!';
    }
    return 'Connect your wallet to start trading!'
  }

  const areButtonsDisabled = !isConnected || !isConnectedToCorrectNetwork;

  return (
    <div className="grid grid-cols-1 md:grid-cols-4">
      <div className='p-4 sm:p-8'>
        <FDogImage />
      </div>
      <div className="col-span-3 p-4 sm:p-8 text-center">
        <div>
          <HeaderImage />
          <p className="mb-4">{renderWalletConnectedInfo()}</p>

          <ConnectWalletButton className="mb-4" />

          <FullWidthButton disabled={areButtonsDisabled} className="w-full mb-4" onClick={handleCreateOfferClick}>Create an offer</FullWidthButton>

          <div className="grid grid-cols-2 gap-2 mb-4">
            <TabButton variant={activeTab === 'active' ? 'contained' : 'outlined'} onClick={() => setActiveTab('active')}>Active offers</TabButton>
            <TabButton variant={activeTab === 'expired' ? 'contained' : 'outlined'} disabled={areButtonsDisabled} onClick={() => setActiveTab('expired')}>Expired offers</TabButton>
          </div>
        </div>

        <TableContainer className="w-full p-4">
          {
            activeTab === 'active'
              ? <ActiveOffersTable />
              : <ExpiredOffersTable />
          }
        </TableContainer>
      </div>

      <Modal open={isCreateOfferModalOpen} onClose={handleCreateOfferModalClose} title="Create an offer">
        <label htmlFor='fdog-amount'>Amount of $fdog you provide?</label>
        <Input id='fdog-amount' disabled={inputsDisabled} className="mb-4" value={fdogAmount} onChange={setFDogAmount} />

        <label htmlFor='price'>Price in $ftm you want to get</label>
        <Input id='price' disabled={inputsDisabled} className="mb-4" value={price} onChange={setPrice} />

        <label htmlFor='minutes'>Duration in minutes of your offer</label>
        <Input id='minutes' disabled={inputsDisabled} className="mb-4" value={minutes} onChange={setMinutes} />

        <div className="mt-4">
          <Button disabled={isCreatingOffer} onClick={handleCreateOrder} >{isCreatingOffer ? 'Is creating offer...' : 'Create offer!'}</Button>
        </div>
      </Modal>
    </div>
  );

}