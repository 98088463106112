import { OfferEntity } from "../../../services/api/IApiService";
import getApiService from "../../../services/api/getApiService";
import { AppDispatch, GetAppState } from "../../../store";
import { Offer, offersForWalletUpdated, offersLoading, offersUpdated, } from "./offersSlice";

export enum OfferStatus {
    Active,
    Expired
}

const mapOfferEntityToOffer = (offerEntity: OfferEntity): Offer => ({
    id: offerEntity.OfferID,
    seller: offerEntity.Seller,
    amount: offerEntity.Amount,
    price: offerEntity.Price,
    expiry: offerEntity.Expiry,
})

const fetchOffers = () => {
    return async (dispatch: AppDispatch, getState: GetAppState) => {
        dispatch(offersLoading());

        const walletAddress = getState().wallet.address;
        const apiService = getApiService();

        const promises = [apiService.getActiveOffers()];
        if (walletAddress) {
            promises.push(apiService.getExpiredOffers(walletAddress));
        }

        const [active, expired] = await Promise.all(promises);

        dispatch(offersUpdated({activeOffers: active.map(mapOfferEntityToOffer)}));
        if (walletAddress && Array.isArray(expired)) {
            dispatch(offersForWalletUpdated({ expiredOffers: expired.map(mapOfferEntityToOffer), walletAddress }));
        }
    }
}

export default fetchOffers;