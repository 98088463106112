import { BigNumber } from "ethers";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { RootState } from "../../../store";
import createOffer from "../redux/createOffer";

export type UseCreateOfferOutput = {
  createOffer: (amount: BigNumber, price: BigNumber, durationInMinutes: number) => Promise<void>;
  isCreatingOffer: boolean;
}

export default function useCreateOffer(): UseCreateOfferOutput {
  const dispatch = useAppDispatch()
  const { creatingOffer } = useSelector((state: RootState) => state.offers);
  const boundCreateOffer = useCallback(async (amount: BigNumber, price: BigNumber, durationInMinutes: number) => {
    await dispatch(createOffer(amount, price, durationInMinutes))
  }, [dispatch]);

  return {
    createOffer: boundCreateOffer,
    isCreatingOffer: creatingOffer
  }
}