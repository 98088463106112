import { Route, Routes } from 'react-router';
import styled from 'styled-components';
import Footer from './components/Footer';
import NotificationContainer from './features/notifications/NotificationContainer';
import OffersPollingContainer from './features/offers/OffersPollingContainer';
import MainView from './views/MainView/MainView';
import Header from './components/Header';

const Layout = styled.div.attrs({ className: "flex flex-col justify-between" })`
  min-height: 100vh;
`;

const Container = styled.div.attrs({ className: 'mx-auto' })`
  max-width: 1280px;
  background-color: rgba(255,255,255,.9);
  width: 100%;
`;

const FooterContainer = styled.div``;

const OFFERS_POLLING_INTERVAL_MS = 15 * 1000;

const App: React.FC = () => {
  return (
    <>
      <Layout>
        <Container>
          <Header />
          <Routes>
            <Route path="/" element={<MainView />} />
          </Routes>
        </Container>

        <FooterContainer>
          <Footer />
        </FooterContainer>
      </Layout>

      <OffersPollingContainer pollingIntervalMs={OFFERS_POLLING_INTERVAL_MS} />

      <NotificationContainer />
    </>
  );
};

export default App;
