import { createWeb3Modal as createWeb3ModalOriginal, defaultConfig } from '@web3modal/ethers5/react';
import getNetworks from '../../networks/getNetworks';

export type Web3Modal = ReturnType<typeof createWeb3ModalOriginal>;

const RABBY_WALLET_ID = '18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1';

export const createWeb3Modal = (): Web3Modal => {
    const modal = createWeb3ModalOriginal({
        featuredWalletIds: [RABBY_WALLET_ID],
        ethersConfig: defaultConfig({
            metadata: {
                name: '$fDog',
                description: '$fDOG Flash Bonds',
                url: process.env.REACT_APP_METADATA_APPLICATION_URL ?? 'https://bonds.fdogftm.com/',
                icons: []
            }
        }),
        chains: getNetworks().map((network) => ({
            chainId: network.id,
            name: network.name,
            currency: network.currency,
            explorerUrl: network.explorerUrl,
            rpcUrl: network.publicRpcUrl
        })),
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '6afca52294f31e6766b16ecac0e6b14d' // Project id for fdog
    });

    return modal;
};