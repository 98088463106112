import styled from "styled-components";

export enum TokenType {
  FTM = 'ftm',
  fDOG = 'fdog'
}

export type TokenProps = {
  className?: string;
  type: TokenType;
  size: number;
}

const TOKEN_IMAGE_MAP: Record<TokenType, { path: string, alt: string }> = {
  [TokenType.FTM]: { path: '/images/tokens/ftm.png', alt: 'wFTM logo' },
  [TokenType.fDOG]: { path: '/images/tokens/fdog.png', alt: 'fDOG logo' }
}

const TokenImage = styled.img<{ size: number }>`
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

export default function Token({ className, type, size }: TokenProps) {
  const image = TOKEN_IMAGE_MAP[type];
  return <TokenImage size={size} className={className} src={image.path} alt={image.alt} />
}