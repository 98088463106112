import { useCallback, useEffect } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import fetchOffers from "./redux/fetchOffers";
import useAppSelector from "../../hooks/useAppSelector";

export type OffersPollingContainerProps = {
    pollingIntervalMs: number;
}


const OffersPollingContainer: React.FC<OffersPollingContainerProps> = ({
    pollingIntervalMs
}) => {
    const dispatch = useAppDispatch();
    const { address } = useAppSelector(state => state.wallet);
    const boundAction = useCallback(() => {
        dispatch(fetchOffers());
    }, [dispatch]);

    useEffect(() => {
        boundAction();

        const handle = setInterval(() => {
            boundAction();
        }, pollingIntervalMs);

        return () => {
            clearInterval(handle);
        }
    }, [boundAction, pollingIntervalMs, address]);

    return <></>
}

export default OffersPollingContainer;