import Network from '../Network';

export const FANTOM_NETWORK_ID = 250;
const FANTOM_PUBLIC_RPC = 'https://rpc.ankr.com/fantom/';

export const fantom: Network = {
    id: FANTOM_NETWORK_ID,
    chainIdentifier: 'iota',
    publicRpcUrl: FANTOM_PUBLIC_RPC,
    name: 'Fantom',
    currency: 'FTM',
    explorerUrl: 'https://ftmscan.com//',
    testnet: false,
};
