import { BigNumber, Contract, ContractTransaction } from 'ethers';
import { AppDispatch, GetAppState } from '../../../store';
import getSignerOrProvider from '../../wallet/getSignerOrProvider';
import {
  NotificationType,
  notificationAdded,
} from '../../notifications/redux/notificationsSlice';
import { resolveFriendlyErrorMessage } from '../../notifications/redux/utils';
import { collectingExpiredOffer, collectingExpiredOfferFailed, expiredOfferCollected } from './offersSlice';
import { OTC_ABI, OTC_CONTRACT_ADDRESS } from '../../../constants';
import { fantom } from '../../../networks/configurations/fantom';

const collectExpiredOffer = (offerId: string) => {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { wallet } = getState();
    if (!wallet.connected) {
      return;
    }

    dispatch(collectingExpiredOffer({ offerId }));
    try {
      const signer = getSignerOrProvider(fantom.id, wallet);

      const otcContract = new Contract(
        OTC_CONTRACT_ADDRESS,
        OTC_ABI,
        signer
      );

      const expireOfferTx: ContractTransaction = await otcContract.expireOffer(BigNumber.from(offerId));
      await expireOfferTx.wait();
      dispatch(expiredOfferCollected({ offerId }))

      notificationAdded({
        title: "Offer collected",
        message: 'Expired offer collected succesfully.',
        type: NotificationType.success,
      });
    } catch (e: any) {
      console.error(`Collecting offer ${offerId} failed`, e);
      dispatch(collectingExpiredOfferFailed({ offerId }));
      dispatch(
        notificationAdded({
          title: "Offer collecting failed",
          message: resolveFriendlyErrorMessage(e?.message || ''),
          type: NotificationType.error,
        })
      );
    };
  }
};

export default collectExpiredOffer;
