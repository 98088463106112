import { ReactNode } from "react";
import { Offer } from "../../../features/offers/redux/offersSlice";
import styled from "styled-components";
import SmallScreenOffersTable from "./SmallScreenOffersTable";
import TokenAmount from "../../../components/TokenAmount";
import { TokenType } from "../../../components/Token";
import Loading from "../../../components/Loading";
import useScreenSize from "../../../hooks/useScreenSize";
import { isSmallerOrEqualTo } from "../../../utils/screenSizes";

export type OfferTableSortableColumn = 'price' | 'amount' | 'expiry';
export type OfferTableSortableDirection = 'asc' | 'desc';

export type OffersTableProps = {
  offers: Offer[];
  onSort: (column: OfferTableSortableColumn, direction: OfferTableSortableDirection) => void;
  renderAction: (offer: Offer) => ReactNode;
  noOffersText: string;
  isLoading: boolean;
}

const Table = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const Row = styled.div<{ hasBottomBorder?: boolean }>`
  border-bottom: ${props => props.hasBottomBorder ? `1px solid #98a1c0` : 'none'};
  display: flex;
  width: 100%;
  padding: .5rem 0;
`;

const HeadingRow = styled(Row)`
  font-weight: bold;
`;

const Cell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const CellAlignCenter = styled(Cell)`
  justify-content: center;
`;

const CellAlignRight = styled(Cell)`
  justify-content: end;
`;

export default function OffersTable(props: OffersTableProps) {
  const screenSize = useScreenSize();
  const isSmallScreen = isSmallerOrEqualTo(screenSize, 'sm');

  if (isSmallScreen) {
    return <SmallScreenOffersTable {...props} />
  }

  const { isLoading, noOffersText, offers, onSort, renderAction } = props;

  const renderContent = () => {
    if (isLoading) {
      return (
        <Row>
          <CellAlignCenter className="py-4">
            <Loading />
          </CellAlignCenter>
        </Row>
      );
    }

    if (offers.length === 0) {
      return (
        <Row className="py-4">
          <CellAlignCenter>{noOffersText}</CellAlignCenter>
        </Row>
      );
    }

    return offers.map((offer, index) => (
      <Row key={offer.id} hasBottomBorder={index !== offers.length - 1}>
        <Cell><TokenAmount amount={offer.price} token={TokenType.FTM} /></Cell>
        <Cell><TokenAmount amount={offer.amount} token={TokenType.fDOG} /></Cell>
        <Cell className="col-span-2">{offer.expiry}</Cell>
        <CellAlignRight className="col-span-2">{renderAction(offer)}</CellAlignRight>
      </Row>
    ))
  }

  return (
    <Table>
      <HeadingRow>
        <Cell>Price</Cell>
        <Cell>Offer</Cell>
        <Cell>Expires in</Cell>
        <Cell />
      </HeadingRow>
      {renderContent()}
    </Table>
  )
}