import { useEffect, useState } from "react";
import styled from "styled-components";
import { createWeb3Modal, Web3Modal } from "../features/wallet/createWeb3Modal";
import useWallet from "../features/wallet/hooks/useWallet";
import Button from "./Button";

export type ConnectWalletButtonProps = {
  className?: string;
}

const ConnectButton = styled(Button)``;

const createShortWalletAddress = (walletAddress: string) => ([
  walletAddress.substring(0, 5),
  walletAddress.substring(walletAddress.length - 4, walletAddress.length)
].join('...'));

const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
  className
}) => {
  const [web3Modal, setWeb3Modal] = useState<Web3Modal | null>(null);
  const { signer, connectWallet, address, connected, web3ModalConnected, networkId } = useWallet();

  useEffect(() => {
    if (web3Modal && web3ModalConnected && address && networkId && !signer) {
      connectWallet(web3Modal);
    }

  }, [web3ModalConnected, address, networkId, web3Modal]);

  useEffect(() => {
    const modal = createWeb3Modal();
    setWeb3Modal(modal);
  }, []);

  const renderConnectButton = () => {
    const handleClick = () => {
      web3Modal?.open();
    }

    return (
      <ConnectButton onClick={handleClick}>
        {connected && address ? createShortWalletAddress(address) : 'Connect Wallet'}
      </ConnectButton>
    )
  }

  return (
    <div className={className}>
      {renderConnectButton()}
    </div>
  )
}

export default ConnectWalletButton;