import styled from 'styled-components';
// Assuming you're using SVGs or images
import XImage from '../resources/assets/x.svg';
import DexScreener from '../resources/assets/dexscreener.svg';

export type FooterProps = {
    className?: string;
};

const Container = styled.div`
  min-height: 100px;
  /* background: #ffbc42; */
  display: flex;
  align-items: center; // To center the content vertically
`;

const InnerContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between; // To space out the social links and other content
`;

const SocialImage = styled.img`
    width: 30px;
`;

const SocialLinks = styled.div`
  display: flex;
  justify
  gap: 4rem; // Space between the social links
`;

const Footer: React.FC<FooterProps> = ({ className }) => {
    return (
        <Container className={className}>
            <InnerContainer>
                <SocialLinks className='flex justify-center gap-12'>
                    <a
                        href="https://x.com/fdog_ftm"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SocialImage src={XImage} alt="X" />
                    </a>
                    <a
                        href="https://dexscreener.com/fantom/0xb63572ce5bcf5df2f954c62a2b6d28045f45fc43"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SocialImage src={DexScreener} alt="DexScreener" />
                    </a>
                </SocialLinks>
            </InnerContainer>
        </Container>
    );
};

export default Footer;
