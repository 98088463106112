import { utils } from "ethers";
import OffersTable from "./OffersTable";
import { Offer } from "../../../features/offers/redux/offersSlice";
import useAcceptOffer from "../../../features/offers/hooks/useAcceptOffer";
import useAppSelector from "../../../hooks/useAppSelector";
import { WFTM_DECIMALS } from "../../../constants";
import TableButton from "./TableButton";


export default function ActiveOffersTable() {
  const isConnected = useAppSelector(state => state.wallet.connected);
  const { allOffers, loading, initialized } = useAppSelector(state => state.offers);
  const { acceptOffer, isAcceptingOffers } = useAcceptOffer();

  const renderAction = (offer: Offer) => {
    const handleClick = async () => {
      await acceptOffer(offer.id, utils.parseUnits(offer.price, WFTM_DECIMALS));
    }

    const isAcceptButtonDisabled = !isConnected || isAcceptingOffers.includes(offer.id);
    return (
      <TableButton onClick={handleClick} disabled={isAcceptButtonDisabled}>
        Accept offer
      </TableButton>
    )
  }

  return (
    <OffersTable
      isLoading={!initialized && loading}
      offers={allOffers.active}
      onSort={() => { }}
      renderAction={renderAction}
      noOffersText="No offers available"
    />
  )
}