import styled from "styled-components";

const Button = styled.button<{ variant?: 'contained' | 'outlined' }>`
    background-color: ${props => props.variant === 'outlined' ? 'transparent' : '#354782'};
    border: 3px solid #354782;
    border-radius: .25rem;
    color:  ${props => props.variant === 'outlined' ? '#000' : '#fff'};
    font-size: .9rem;
    padding: .75rem 1.25rem;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      border: 3px solid #5d6ea9;
      background-color: ${props => props.variant === 'outlined' ? 'rgba(0,0,0,0.05)' : '#5d6ea9'};
    }

`;

export default Button;
