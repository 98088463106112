import { IApiService, OfferEntity } from "./IApiService";

export class ApiService implements IApiService {
  constructor(private baseUrl: string) { }

  public async getActiveOffers(): Promise<OfferEntity[]> {
    const url = `${this.baseUrl}/api/v1/offers/active`;
    const res = await fetch(url, { headers: { 'Content-Type': 'application/json' } });
    return res.json();
  }

  public async getExpiredOffers(walletAddress: string): Promise<OfferEntity[]> {
    const searchParams = new URLSearchParams();
    searchParams.set('address', walletAddress);
    const url = `${this.baseUrl}/api/v1/offers/expired?${searchParams.toString()}`;
    const res = await fetch(url, { headers: { 'Content-Type': 'application/json' } });
    return res.json();
  }

  private async wait() {
    await new Promise(resolve => setTimeout(resolve, 2000));
  }

}