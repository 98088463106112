import styled from "styled-components";
import TokenAmount from "../../../components/TokenAmount";
import { TokenType } from "../../../components/Token";
import Loading from "../../../components/Loading";
import { OffersTableProps } from "./OffersTable";

export type SmallScreenOffersTable = OffersTableProps;

const Item = styled.div<{ hasBottomBorder?: boolean }>`
  border-bottom: ${props => props.hasBottomBorder ? `1px solid #98a1c0` : 'none'};
  width: 100%;
  padding: .5rem 0;
`;

const Container = styled.div``;

const Text = styled.div`
  text-align: left;
  padding: .5rem;
`;

const Label = styled(Text).attrs({ className: 'col-span-2' })`
  font-weight: bold;
`;
const Value = styled(Text).attrs({ className: 'col-span-3' })``;

export default function SmallScreenOffersTable({ offers, onSort, noOffersText, renderAction, isLoading }: OffersTableProps) {

  const renderContent = () => {
    if (isLoading) {
      return (
        <Item className="p-4 flex justify-center">
          <Loading />
        </Item>
      );
    }

    if (offers.length === 0) {
      return (
        <Item className="p-4 flex justify-center">{noOffersText}</Item>
      );
    }

    return offers.map((offer, index) => (
      <Item key={offer.id} hasBottomBorder={index !== offers.length - 1}>
        <div className="grid grid-cols-5">
          <Label>Price</Label>
          <Value><TokenAmount amount={offer.price} token={TokenType.FTM} /></Value>
          <Label>Offer</Label>
          <Value><TokenAmount amount={offer.amount} token={TokenType.fDOG} /></Value>
          <Label>Expires in</Label>
          <Value>{offer.expiry}</Value>
          <div className="col-span-5 flex justify-center">
            {renderAction(offer)}
          </div>
        </div>
      </Item>
    ))
  }

  return (
    <Container >
      {renderContent()}
    </Container>
  )
}