import styled from "styled-components"

const BannerImage = styled.img`
  width: 100%;
`;

export default function Header() {

  return <BannerImage src='/images/banner.jpg' />

}