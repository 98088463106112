import OffersTable from "./OffersTable";
import { Offer } from "../../../features/offers/redux/offersSlice";
import useCollectExpiredOffer from "../../../features/offers/hooks/useCollectExpiredOffer";
import useAppSelector from "../../../hooks/useAppSelector";
import TableButton from "./TableButton";

export default function ExpiredOffersTable() {
  const { address } = useAppSelector(state => state.wallet);
  const { initialized, loading, byWalletAddress } = useAppSelector(state => state.offers);
  const { collectExpiredOffer, isCollectingOffers } = useCollectExpiredOffer();

  const renderAction = (offer: Offer) => {
    const handleClick = async () => {
      await collectExpiredOffer(offer.id);
    }

    return (
      <TableButton onClick={handleClick} disabled={isCollectingOffers.includes(offer.id)}>
        Collect expired offer
      </TableButton>
    )
  }

  return (
    <OffersTable
      isLoading={!initialized && loading}
      offers={byWalletAddress[address]?.expired ?? []}
      onSort={() => { }}
      renderAction={renderAction}
      noOffersText="You have no expired offers available."
    />
  )
}