import Token, { TokenType } from "./Token";
import styled from "styled-components";

export type TokenAmountProps = {
  amount: string;
  token: TokenType;
}

const Root = styled.div`
  position: relative;
`;

const PositionedToken = styled(Token)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Amount = styled.div`
  padding-left: 36px;
`;

export default function TokenAmount({
  amount,
  token,
}: TokenAmountProps) {
  const amountFixed = parseFloat(amount).toFixed(4);

  return (
    <Root>
      <PositionedToken size={28} type={token} />
      <Amount>{amountFixed}</Amount>
    </Root>
  )
}