import { ApiService } from "./ApiService";
import { IApiService } from "./IApiService";

let apiService: IApiService;

export default function getApiService(): IApiService {
  if (!apiService) {
    apiService = new ApiService('https://fdog.proofofze.dev');
  }

  return apiService;
}