import { configureStore } from "@reduxjs/toolkit";

import walletReducer from "./features/wallet/redux/walletSlice";
import offersReducer from "./features/offers/redux/offersSlice";
import notificationsReducer from "./features/notifications/redux/notificationsSlice";

const store = configureStore({
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    'wallet/walletConnected'
                ],
                ignoredPaths: [
                    'wallet.signer'
                ]
            }
        });
    },
    reducer: {
        wallet: walletReducer,
        offers: offersReducer,
        notifications: notificationsReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type GetAppState = typeof store.getState;

export default store;