import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Offer = {
    id: string;
    seller: string;
    amount: string;
    price: string;
    expiry: string;
}

export type OffersState = {
    byWalletAddress: {
        [walletAddress: string]: {
            expired: Offer[];
        }
    }
    allOffers: {
        active: Offer[];
    },
    initialized: boolean;
    loading: boolean;
    creatingOffer: boolean;
    acceptingOffers: string[];
    collectingExpiredOffers: string[];
}

const initialState: OffersState = {
    byWalletAddress: {},
    allOffers: { active: [] },
    initialized: false,
    creatingOffer: false,
    loading: false,
    acceptingOffers: [],
    collectingExpiredOffers: []
}

const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        offersLoading: (state) => ({
            ...state,
            loading: true
        }),
        offersUpdated: (state, action: PayloadAction<{ activeOffers: Offer[] }>) => {
            const { activeOffers } = action.payload;
            return ({
                ...state,
                initialized: true,
                loading: false,
                allOffers: {
                    active: activeOffers
                }
            })
        },
        creatingOffer: (state) => ({
            ...state,
            creatingOffer: true
        }),
        creatingOfferFailed: (state) => ({
            ...state,
            creatingOffer: false
        }),
        offerCreated: (state) => ({
            ...state,
            creatingOffer: false
        }),
        collectingExpiredOffer: (state, action: PayloadAction<{ offerId: string }>) => ({
            ...state,
            collectingExpiredOffers: [...state.collectingExpiredOffers, action.payload.offerId]
        }),
        collectingExpiredOfferFailed: (state, action: PayloadAction<{ offerId: string }>) => ({
            ...state,
            collectingExpiredOffers: state.collectingExpiredOffers.filter(id => id !== action.payload.offerId)
        }),
        expiredOfferCollected: (state, action: PayloadAction<{ offerId: string }>) => ({
            ...state,
            collectingExpiredOffers: state.collectingExpiredOffers.filter(id => id !== action.payload.offerId)
        }),
        acceptingOffer: (state, action: PayloadAction<{ offerId: string }>) => ({
            ...state,
            acceptingOffers: [...state.acceptingOffers, action.payload.offerId]
        }),
        acceptingOfferFailed: (state, action: PayloadAction<{ offerId: string }>) => ({
            ...state,
            acceptingOffers: state.acceptingOffers.filter(id => id !== action.payload.offerId)
        }),
        offerAccepted: (state, action: PayloadAction<{ offerId: string }>) => ({
            ...state,
            acceptingOffers: state.acceptingOffers.filter(id => id !== action.payload.offerId)
        }),
        offersForWalletUpdated: (state, action: PayloadAction<{ walletAddress: string, expiredOffers: Offer[] }>) => {
            const { expiredOffers, walletAddress } = action.payload;
            return ({
                ...state,
                initialized: true,
                loading: false,
                byWalletAddress: {
                    ...state.byWalletAddress,
                    [walletAddress]: {
                        expired: expiredOffers
                    }
                }
            })
        },
    }
});

export const {
    offersLoading,
    offersUpdated,
    offersForWalletUpdated,
    creatingOffer,
    offerCreated,
    collectingExpiredOffer,
    expiredOfferCollected,
    acceptingOffer,
    offerAccepted,
    acceptingOfferFailed,
    collectingExpiredOfferFailed,
    creatingOfferFailed
} = offersSlice.actions

export default offersSlice.reducer;