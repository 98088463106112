import { BigNumber, Contract, ContractTransaction } from 'ethers';
import { AppDispatch, GetAppState } from '../../../store';
import getSignerOrProvider from '../../wallet/getSignerOrProvider';
import {
  NotificationType,
  notificationAdded,
} from '../../notifications/redux/notificationsSlice';
import { resolveFriendlyErrorMessage } from '../../notifications/redux/utils';
import { acceptingOffer, acceptingOfferFailed, offerAccepted } from './offersSlice';
import { ERC20_ABI, OTC_ABI, OTC_CONTRACT_ADDRESS, WFTM_CONTRACT_ADDRESS } from '../../../constants';
import { fantom } from '../../../networks/configurations/fantom';

const acceptOffer = (offerId: string, ftmAmount: BigNumber) => {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { wallet } = getState();
    if (!wallet.connected) {
      return;
    }

    dispatch(acceptingOffer({ offerId }));
    try {
      const signer = getSignerOrProvider(fantom.id, wallet);

      const wftmContract = new Contract(
        WFTM_CONTRACT_ADDRESS,
        ERC20_ABI,
        signer
      )
      const otcContract = new Contract(
        OTC_CONTRACT_ADDRESS,
        OTC_ABI,
        signer
      );

      const approveTx: ContractTransaction = await wftmContract.approve(
        OTC_CONTRACT_ADDRESS,
        ftmAmount
      );
      await approveTx.wait();

      const acceptOfferTx: ContractTransaction = await otcContract.acceptOffer(BigNumber.from(offerId));
      await acceptOfferTx.wait();

      dispatch(offerAccepted({ offerId }));

      notificationAdded({
        title: "Offer accepted",
        message: 'Offer accepted succesfully.',
        type: NotificationType.success,
      });
    } catch (e: any) {
      console.error(`Calling offer accepting failed ${offerId}`, e);
      dispatch(acceptingOfferFailed({ offerId }));
      dispatch(
        notificationAdded({
          title: "Offer accepting failed",
          message: resolveFriendlyErrorMessage(e?.message || ''),
          type: NotificationType.error,
        })
      );
    }
  };
};

export default acceptOffer;
