import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { RootState } from "../../../store";
import collectExpiredOffer from "../redux/collectExpiredOffer";

export type UseCollectExpiredOfferOutput = {
  collectExpiredOffer: (offerId: string) => Promise<void>;
  isCollectingOffers: string[];
}

export default function useCollectExpiredOffer(): UseCollectExpiredOfferOutput {
  const dispatch = useAppDispatch()
  const { collectingExpiredOffers } = useSelector((state: RootState) => state.offers);
  const boundCollectExpiredOffer = useCallback(async (offerId: string) => {
    dispatch(collectExpiredOffer(offerId))
  }, [dispatch]);

  return useMemo(() => ({
    collectExpiredOffer: boundCollectExpiredOffer,
    isCollectingOffers: collectingExpiredOffers
  }), [boundCollectExpiredOffer, collectingExpiredOffers]);
}