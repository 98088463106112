import { BigNumber, Contract, ContractTransaction } from 'ethers';
import { AppDispatch, GetAppState } from '../../../store';
import getSignerOrProvider from '../../wallet/getSignerOrProvider';
import {
  NotificationType,
  notificationAdded,
} from '../../notifications/redux/notificationsSlice';
import { resolveFriendlyErrorMessage } from '../../notifications/redux/utils';
import { creatingOffer, creatingOfferFailed, offerCreated } from './offersSlice';
import { ERC20_ABI, FDOG_CONTRACT_ADDRESS, OTC_ABI, OTC_CONTRACT_ADDRESS } from '../../../constants';
import { fantom } from '../../../networks/configurations/fantom';
import fetchOffers from './fetchOffers';

const wait = (ms: number) => new Promise(resolve => {
  setTimeout(resolve, ms);
})

const createOffer = (amount: BigNumber, price: BigNumber, durationInMinutes: number) => {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { wallet } = getState();
    if (!wallet.connected) {
      return;
    }

    dispatch(creatingOffer());
    try {
      const signer = getSignerOrProvider(fantom.id, wallet);

      const fdogContract = new Contract(
        FDOG_CONTRACT_ADDRESS,
        ERC20_ABI,
        signer
      )
      const otcContract = new Contract(
        OTC_CONTRACT_ADDRESS,
        OTC_ABI,
        signer
      );

      const approveTx: ContractTransaction = await fdogContract.approve(
        OTC_CONTRACT_ADDRESS,
        amount
      );
      await approveTx.wait();

      const createOfferTx: ContractTransaction = await otcContract.createOffer(amount, price, durationInMinutes);
      await createOfferTx.wait();

      await wait(1500);

      dispatch(fetchOffers());

      dispatch(
        notificationAdded({
          title: "Offer created",
          message: 'New offer created succesfully.',
          type: NotificationType.success,
        })
      );

      dispatch(offerCreated());
    } catch (e: any) {
      console.error(`Calling offer creation`, e);
      dispatch(creatingOfferFailed());
      dispatch(
        notificationAdded({
          title: "Offer creation failed",
          message: resolveFriendlyErrorMessage(e?.message || ''),
          type: NotificationType.error,
        })
      );
    }
  };
};

export default createOffer;
