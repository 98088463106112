import { PropsWithChildren } from 'react';
import ReactModal from 'react-responsive-modal';
import styled from 'styled-components';
import 'react-responsive-modal/styles.css';

export type ModalProps = PropsWithChildren & {
  open: boolean;
  onClose: () => void;
  title: string;
}

const Title = styled.h2`
  font-size: 1.8rem;
`;

export default function Modal({ children, open, onClose, title }: ModalProps) {
  return (
    <ReactModal
      open={open}
      onClose={onClose}
      center={true}
      styles={{
        modal: {
          'width': 450,
          'maxWidth': '95%'
        }
      }}
    >
      <Title className='mb-4'>{title}</Title>
      <div>
        {children}
      </div>
    </ReactModal>
  )
}